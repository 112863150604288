import React from "react";
import Layout from "../layouts/Layout";
import ContentSubLayout from "../layouts/ContentSubLayout";
import CONSTANTS from "../utils/constants";

export default () => {
  return (
    <Layout
      className="page-content page-entretien-chaudiere"
      showSMMenuLink={false}
    >
      <ContentSubLayout
        pageReference={CONSTANTS.CONTENT_PAGE_REFERENCES.ENTRETIEN_CHAUDIERE}
      />
    </Layout>
  );
};
